import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { requestMagicLinkAuth } from 'services/authentication/magicLink';
import { useEffectOnce } from 'usehooks-ts';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import MagicLinkIcon from 'features/shared/icons/MagicLinkIcon';
import { LayoutCard } from 'features/shared/layout';

import { requestMagicLinkSchema } from './validation';

export interface LoginMagicFormInputs {
  email: string;
}

interface RequestMagicLinkProps {
  invalid?: boolean;
}
const RequestMagicLink = ({ invalid = false }: RequestMagicLinkProps) => {
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const handleError = useErrorHandler();

  const { mutate, isLoading, isSuccess } = useMutation(
    (data: LoginMagicFormInputs) => requestMagicLinkAuth(data.email),
    {
      onError: (error: any) => handleError(error)
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<LoginMagicFormInputs>({
    resolver: yupResolver(requestMagicLinkSchema(tForm))
  });

  useEffectOnce(() => {
    if (invalid)
      setError('root', { message: t('login.magicLink.invalidToken') });
  });

  const onSubmit: SubmitHandler<LoginMagicFormInputs> = async (formData) =>
    mutate(formData);

  return (
    <LayoutCard
      title={t('login.title.default')}
      body={
        <div className="grid grid-cols-1 sm:mx-8">
          <div className="w-full">
            {errors.root && (
              <p className="mt-2 text-red-800 mb-4">{errors.root.message}</p>
            )}
            <p className="text-base font-medium text-gray-800 pb-8">
              {isSuccess
                ? t('general.emailSent.p')
                : t('login.description.magic')}
            </p>
            {!isSuccess && (
              <div className="grid grid-cols-1 divide-y">
                <div className="w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex w-full flex-col gap-4"
                  >
                    <TextInputGroup
                      autofocus
                      type="email"
                      fullWidth
                      placeholder={tForm('placeholder.email')}
                      label={tForm('label.email')}
                      name="email"
                      register={register}
                      error={errors.email}
                    />
                    <Button
                      isLoading={isLoading}
                      fullWidth
                      submit
                      icon={<MagicLinkIcon />}
                      size="medium"
                      variant="tertiary"
                      label={t('login.button.submit.label')}
                    />
                  </form>
                  <Link
                    className="border border-black block rounded mt-4 w-full text-black font-medium px-3 py-2 text-sm sm:px-4 sm:py-2 sm:text-base hover:bg-black hover:text-white"
                    to="/login?password=true"
                  >
                    {t('login.switch.password')}
                  </Link>
                </div>
                <div className="flex flex-col -mx-14 pb-5 -mb-6 mt-6 bg-purple-200 text-center">
                  <div className="text-lg font-serif my-5 font-medium">
                    {t('login.redirect.title')}
                  </div>
                  <div>
                    <Trans
                      ns="customer"
                      i18nKey="login.link.onboarding"
                      components={{
                        1: (
                          <Link
                            className="text-purple-800 underline fontbold"
                            to="/onboarding/email"
                          />
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

export default RequestMagicLink;
