import { Popover, Switch, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { signUp } from 'services/authentication/signup';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import MagicLinkIcon from 'features/shared/icons/MagicLinkIcon';

import { signUpSchema } from './validation';

interface SignUpFormInputs {
  email: string;
  hasCode: boolean;
  code: string | null;
}

const Onboarding = ({ onSuccess }: any) => {
  const handleError = useErrorHandler();
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');

  const { mutate, isLoading } = useMutation(
    ({ email, code }: SignUpFormInputs) => signUp(email, code)
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<SignUpFormInputs>({
    defaultValues: { hasCode: false },
    resolver: yupResolver(signUpSchema(tForm))
  });

  const hasCode = watch('hasCode');
  const onSubmit: SubmitHandler<SignUpFormInputs> = async (formData) => {
    mutate(formData, {
      onSuccess: () => onSuccess(),
      onError: (error: any) => handleError(error)
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-6"
    >
      <TextInputGroup
        autofocus
        type="email"
        fullWidth
        placeholder={tForm('placeholder.email')}
        label={tForm('label.email')}
        name="email"
        register={register}
        error={errors.email}
      />
      <fieldset className="flex flex-row items-center">
        <Switch
          checked={hasCode}
          onChange={(checked) => {
            if (!checked) {
              setValue('code', null);
            }
            setValue('hasCode', checked);
          }}
          className={`${!hasCode ? 'bg-gray-500' : 'bg-purple-800'}
            relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            aria-hidden="true"
            className={`${hasCode ? 'translate-x-6' : 'translate-x-0'}
              pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
        <label className="font-sans text-sm text-black ml-2.5">
          {t('onboarding.form.hasCode')}
        </label>
        <Popover className="relative">
          <Popover.Button className="group ml-2 inline-flex font-bold items-center border rounded-2xl text-gray-600 border-gray-400 px-2 py-0.5 text-xs focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
            ?
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="border rounded-xl border-gray-400 bg-white absolute py-4 left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 lg:max-w-xl">
              {tForm('helper.code')}
            </Popover.Panel>
          </Transition>
        </Popover>
      </fieldset>
      {hasCode && (
        <TextInputGroup
          fullWidth
          label={tForm('label.code')}
          name="code"
          register={register}
          error={errors.code}
        />
      )}
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        icon={<MagicLinkIcon />}
        size="medium"
        variant="tertiary"
        label={t('login.button.submit.label')}
      />
    </form>
  );
};

export default Onboarding;
