import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FunnelStep, StepStatus } from 'types/funnel';
import { TFunctionTranslationCustomer } from 'types/translation';

import Steps from './Steps';
import TitleCard from './TitleCard';

export enum QuestionFunnelStep {
  Content = 'content',
  Login = 'login',
  ConfirmationInitial = 'confirmationInitial',
  ConfirmationPayment = 'confirmationPayment',
  Success = 'success'
}

export enum QuestionFunnelType {
  Private = 'private',
  Professional = 'professional'
}
interface QuestionFunnelIndicationsProps {
  step: QuestionFunnelStep;
  hideSteps?: boolean;
  hideTitle?: boolean;
}

function computeQuestionFunnelIndications(
  step: QuestionFunnelStep,
  t: TFunctionTranslationCustomer
): {
  steps: FunnelStep[];
  title: string[];
} {
  switch (step) {
    case QuestionFunnelStep.Content:
      return {
        steps: [
          {
            id: 1,
            label: t('general.question'),
            status: StepStatus.CURRENT
          },
          {
            id: 2,
            label: t('general.contact'),
            status: StepStatus.UPCOMING
          },
          {
            id: 3,
            label: t('general.activation'),
            status: StepStatus.UPCOMING
          }
        ],
        title: [
          t('funnel.question.title.initial.1'),
          t('funnel.question.title.initial.2')
        ]
      };
    case QuestionFunnelStep.Login:
      return {
        steps: [
          {
            id: 1,
            label: t('general.question'),
            status: StepStatus.COMPLETE
          },
          {
            id: 2,
            label: t('general.contact'),
            status: StepStatus.CURRENT
          },
          {
            id: 3,
            label: t('general.activation'),
            status: StepStatus.UPCOMING
          }
        ],
        title: [t('funnel.question.title.contact')]
      };

    case QuestionFunnelStep.ConfirmationInitial:
      return {
        steps: [
          {
            id: 1,
            label: t('general.question'),
            status: StepStatus.COMPLETE
          },
          {
            id: 2,
            label: t('general.contact'),
            status: StepStatus.COMPLETE
          },
          {
            id: 3,
            label: t('general.send'),
            status: StepStatus.CURRENT
          }
        ],
        title: [t('funnel.question.title.confirmation')]
      };

    case QuestionFunnelStep.ConfirmationPayment:
      return {
        steps: [
          {
            id: 1,
            label: t('general.question'),
            status: StepStatus.COMPLETE
          },
          {
            id: 2,
            label: t('general.contact'),
            status: StepStatus.COMPLETE
          },
          {
            id: 3,
            label: t('general.send'),
            status: StepStatus.CURRENT
          }
        ],
        title: [t('funnel.question.title.payment')]
      };

    case QuestionFunnelStep.Success:
      return {
        steps: [
          {
            id: 1,
            label: t('general.question'),
            status: StepStatus.COMPLETE
          },
          {
            id: 2,
            label: t('general.contact'),
            status: StepStatus.COMPLETE
          },
          {
            id: 3,
            label: t('general.send'),
            status: StepStatus.COMPLETE
          }
        ],
        title: [
          t('funnel.question.title.success.1'),
          t('funnel.question.title.success.2')
        ]
      };

    default:
      return {
        steps: [
          {
            id: 1,
            label: t('general.question'),
            status: StepStatus.CURRENT
          },
          {
            id: 2,
            label: t('general.contact'),
            status: StepStatus.UPCOMING
          },
          {
            id: 3,
            label: t('general.activation'),
            status: StepStatus.UPCOMING
          }
        ],
        title: [t('funnel.question.title.payment')]
      };
  }
}

export function QuestionFunnelIndications({
  step,
  hideSteps = false,
  hideTitle = false
}: QuestionFunnelIndicationsProps) {
  const { t } = useTranslation('customer');
  const { funnelSteps, funnelTitle } = useMemo(() => {
    const { steps, title } = computeQuestionFunnelIndications(step, t);
    return { funnelSteps: steps, funnelTitle: title };
  }, [step, t]);

  return (
    <>
      {!hideSteps && <Steps funnelSteps={funnelSteps} />}
      {!hideTitle && <TitleCard title={funnelTitle} />}
    </>
  );
}

export enum SubscriptionFunnelStep {
  Login = 'Login',
  ConfirmationPayment = 'ConfirmationPayment',
  Success = 'Success'
}

interface SubscriptionFunnelIndicationsProps {
  step: SubscriptionFunnelStep;
}

function computeSubscriptionFunnelIndications(
  step: SubscriptionFunnelStep,
  t: TFunctionTranslationCustomer
): {
  steps: FunnelStep[];
  title: string[];
} {
  switch (step) {
    case SubscriptionFunnelStep.Login:
      return {
        steps: [
          {
            id: 1,
            label: t('general.contact'),
            status: StepStatus.CURRENT
          },
          {
            id: 2,
            label: t('general.activation'),
            status: StepStatus.UPCOMING
          }
        ],
        title: [t('funnel.subscription.title.contact')]
      };

    case SubscriptionFunnelStep.ConfirmationPayment:
      return {
        steps: [
          {
            id: 1,
            label: t('general.contact'),
            status: StepStatus.COMPLETE
          },
          {
            id: 2,
            label: t('general.activation'),
            status: StepStatus.CURRENT
          }
        ],
        title: [t('funnel.subscription.title.payment')]
      };

    case SubscriptionFunnelStep.Success:
      return {
        steps: [
          {
            id: 1,
            label: t('general.contact'),
            status: StepStatus.COMPLETE
          },
          {
            id: 2,
            label: t('general.activation'),
            status: StepStatus.COMPLETE
          }
        ],
        title: [
          t('funnel.subscription.title.success.1'),
          t('funnel.subscription.title.success.2')
        ]
      };

    default:
      return {
        steps: [
          {
            id: 1,
            label: t('general.contact'),
            status: StepStatus.CURRENT
          },
          {
            id: 2,
            label: t('general.activation'),
            status: StepStatus.UPCOMING
          }
        ],
        title: [
          t('funnel.subscription.title.success.1'),
          t('funnel.subscription.title.success.2')
        ]
      };
  }
}
export function SubscriptionFunnelIndications({
  step
}: SubscriptionFunnelIndicationsProps) {
  const { t } = useTranslation('customer');
  const { funnelSteps, funnelTitle } = useMemo(() => {
    const { steps, title } = computeSubscriptionFunnelIndications(step, t);
    return { funnelSteps: steps, funnelTitle: title };
  }, [step, t]);
  return (
    <>
      <Steps funnelSteps={funnelSteps} />
      <TitleCard title={funnelTitle} />
    </>
  );
}
