import { useMutation } from 'react-query';

import { requestPasswordReset, updatePassword } from './api';

export function useRequestNewPasswordMutation() {
  return useMutation(({ email }: { email: string }) =>
    requestPasswordReset(email)
  );
}

export function useUpdatePasswordMutation() {
  return useMutation(
    ({
      email,
      token,
      newPassword
    }: {
      email: string;
      token: string;
      newPassword: string;
    }) => updatePassword(email, token, newPassword)
  );
}
