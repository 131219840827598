import { loadStripe } from '@stripe/stripe-js';
import { ANALYTICS, INTENT_QUESTION_SOURCES } from 'constants/analytics';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  useCreateOrderMutation,
  useCreateQuestionMutation
} from 'api/customer/mutations.index';
import { useSubscriptionQuery } from 'api/customer/subscriptions';

import { useCustomer } from 'hooks';

import { useTracking } from 'context';

import {
  QuestionFunnelIndications,
  QuestionFunnelStep
} from 'features/customer/funnels';
import { ConfirmationSuccessQuestion } from 'features/customer/funnels/confirmation/success';
import { LoadingCard } from 'features/shared/loading';

import { CustomerUsageMeter } from 'types/customer';
import { Subscription } from 'types/subscription';

import PartnerQuestionPayment from './PartnerQuestionPayment';

export interface ConfirmationPartnerProps {
  isPayable: boolean;
  skuCode: string;
  productId: number;
  questionContent: string;
  partnerSlug: string;
}

export default function ConfirmationPartner({
  isPayable,
  productId,
  skuCode,
  questionContent,
  partnerSlug
}: ConfirmationPartnerProps) {
  const { analytics } = useTracking();
  const queryClient = useQueryClient();
  const { id: customerId } = useCustomer();

  const [intentClientSecret, setIntentClientSecret] = useState<string>('');

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUB_KEY as string
  );

  const { mutate: createOrderMutate, isSuccess: isCreateOrderSuccess } =
    useCreateOrderMutation();

  const { data: subscription, isSuccess: isSubscriptionSuccess } =
    useSubscriptionQuery({
      customerId,
      enabled: isCreateOrderSuccess
    });

  const { mutate: createQuestionMutate, isSuccess: isCreateQuestionSuccess } =
    useCreateQuestionMutation();

  const handleOptimisticSubscriptionSuccess = () => {
    const currentSubscription: Subscription = queryClient.getQueryData([
      'subscription'
    ])!;
    const currentCustomerUsageMeter: CustomerUsageMeter =
      queryClient.getQueryData(['customer-usage-meter'])!;
    // Set subscription as active
    queryClient.setQueryData<Subscription>(['subscription'], {
      ...currentSubscription,
      status: 'active',
      active: true
    });
    // Set customer usage meter with hasActiveSubscription
    queryClient.setQueryData<CustomerUsageMeter>(['customer-usage-meter'], {
      ...currentCustomerUsageMeter,
      hasActiveSubscription: true,
      canAskNewQuestion: false
    });
  };

  const handleTrackQuestionAskedSuccess = () =>
    analytics?.track(ANALYTICS.QUESTION_SUCCESS, {
      intentQuestionSource: INTENT_QUESTION_SOURCES.FUNNEL
    });

  useEffect(() => {
    if (!isPayable) {
      // isPayable === false
      if (!isSubscriptionSuccess) {
        // 1st
        createOrderMutate({
          paymentProvider: 'third_party',
          customerId,
          productId
        });
        // Will trigger isSubscriptionSuccess
      } else {
        // 2nd
        createQuestionMutate(
          {
            customerId,
            subscriptionId: subscription?.id as string,
            questionContent
          },
          {
            onSuccess: () => {
              handleTrackQuestionAskedSuccess();
              handleOptimisticSubscriptionSuccess();
            }
          }
        );
      }
    } else if (isPayable && !isSubscriptionSuccess) {
      // isPayable === true
      createOrderMutate(
        {
          paymentProvider: 'stripe',
          customerId,
          productId
        },
        {
          onSuccess: (order) => {
            const { paymentData } = order;
            setIntentClientSecret(paymentData.payment_intent.client_secret);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscriptionSuccess]);

  if (isPayable) {
    return (
      <PartnerQuestionPayment
        skuCode={skuCode}
        questionContent={questionContent}
        subscriptionId={subscription?.id as string}
        intentClientSecret={intentClientSecret}
        stripePromise={stripePromise}
      />
    );
  }

  if (!isCreateQuestionSuccess) {
    return (
      <>
        <QuestionFunnelIndications
          step={QuestionFunnelStep.ConfirmationPayment}
          hideTitle
        />
        <LoadingCard />
      </>
    );
  }

  return <ConfirmationSuccessQuestion />;
}
