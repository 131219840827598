import { DEFAULT_REDIRECT_URL } from 'constants/customer';
import { NotificationList } from 'containers/notification';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useCustomerQuery } from 'api/customer/customers';

import {
  AccountPage,
  AdministratorsPage,
  AlphabetSheetPage,
  ArticlePage,
  AskQuestionPage,
  CollaboratorsPage,
  ConsultationAppointmentVotePage,
  ConsultationBookingPage,
  ConsultationChoicePage,
  ConsultationsPage,
  ContactPage,
  FunnelConfirmationPage,
  HomePage,
  NpsVotePage,
  PrivateContentPage,
  ProfilePage,
  QuestionAnswerVote,
  QuestionPage,
  QuestionsPage,
  ResourcesPage,
  ThematicsPage,
  UpdatePasswordPage,
  VideoPage
} from 'pages/customer';
import AdminPage from 'pages/customer/AdminPage';
import {
  PasswordCompletionPage,
  ProfileCompletionPage,
  SituationCompletionPage,
  ThematicsCompletionPage
} from 'pages/customer/onboarding';
import { NotFoundErrorPage } from 'pages/shared/errors/not-found';

import { useAuth } from 'context';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

const CustomerRouter = () => {
  const customer = useCustomerQuery();
  const { roles } = useAuth();

  if (!customer.isSuccess) return <LayoutLoadingSkeleton />;

  return (
    <Routes>
      <Route path="" element={<Navigate to={DEFAULT_REDIRECT_URL} replace />} />
      {process.env.REACT_APP_EKIE_ADMIN_AVAILIBILITY === '1' &&
        roles.includes('ROLE_CUSTOMER_ADMIN') && (
          <Route path="admin" element={<AdminPage />}>
            <Route path="collaborators" element={<CollaboratorsPage />} />
            <Route path="settings" element={<AdministratorsPage />} />
          </Route>
        )}
      <Route path="account" element={<AccountPage />}>
        <Route path="notifications" element={<NotificationList />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="home" element={<HomePage />} />
        <Route
          path="dashboard"
          element={<Navigate replace to={DEFAULT_REDIRECT_URL} />}
        />
        <Route path="questions" element={<QuestionsPage />} />
        <Route path="consultations" element={<ConsultationsPage />} />
        <Route path="guarantees" element={<ThematicsPage />} />
        <Route path="resources" element={<ResourcesPage />} />
        <Route path="resources/videos" element={<ResourcesPage />} />
        <Route path="videos/:id" element={<VideoPage />} />
        <Route path="resources/documents" element={<ResourcesPage />} />
        <Route path="resources/alphabet" element={<ResourcesPage />} />
        <Route path="resources/:slug" element={<ArticlePage />} />
        <Route path="alphabet/:slug" element={<AlphabetSheetPage />} />
        <Route
          path="consultations/choice"
          element={<ConsultationChoicePage />}
        />
        <Route
          path="consultations/booking"
          element={<ConsultationBookingPage />}
        />
        <Route path="ask" element={<AskQuestionPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="password-new" element={<UpdatePasswordPage />} />
      </Route>
      <Route path="questions" element={<AccountPage />}>
        <Route path=":questionId" element={<QuestionPage />} />
      </Route>
      <Route path="questions">
        <Route path="vote/link/:voteLinkId" element={<QuestionAnswerVote />} />
      </Route>
      <Route
        path="consultations/vote/link/:voteLinkId"
        element={<ConsultationAppointmentVotePage />}
      />
      <Route path="funnel">
        <Route path="confirmation" element={<FunnelConfirmationPage />} />
        <Route
          path="question/contact"
          element={<Navigate to={DEFAULT_REDIRECT_URL} replace />}
        />
      </Route>
      <Route path="nps/vote/:accountId" element={<NpsVotePage />} />
      <Route path="onboarding">
        <Route path="profile" element={<ProfileCompletionPage />} />
        <Route path="password" element={<PasswordCompletionPage />} />
        <Route path="situation" element={<SituationCompletionPage />} />
        <Route path="thematics" element={<ThematicsCompletionPage />} />
        <Route
          path="email"
          element={<Navigate to={DEFAULT_REDIRECT_URL} replace />}
        />
      </Route>
      <Route path="content/:slug" element={<PrivateContentPage />} />
      <Route path="*" element={<NotFoundErrorPage />} />
      <Route
        path="login"
        element={<Navigate to={DEFAULT_REDIRECT_URL} replace />}
      />
      <Route
        path="authenticate"
        element={<Navigate to={DEFAULT_REDIRECT_URL} replace />}
      />
    </Routes>
  );
};

export default CustomerRouter;
