import { useQuery } from 'react-query';

import data from './documentData.json';

interface AllDocuments {
  documents: Document[];
}

type Document = {
  id: string;
  name: string;
  cseOnly: boolean;
  file: {
    size: number;
    url: string;
    format: string;
  };
};

export function useGetDocuments() {
  return useQuery([`documents`], (): AllDocuments | null => ({
    documents: data
  }));
}
