import { QuestionFunnelType } from 'features/customer/funnels';

import { ApiProduct, Product } from 'types/product';

export interface FunnelStep {
  id: number;
  label: string;
  status: StepStatus;
}

export enum StepStatus {
  COMPLETE = 'complete',
  CURRENT = 'current',
  UPCOMING = 'upcoming'
}

export type FunnelContext =
  | 'subscription_funnel'
  | 'question_funnel'
  | 'login'
  | 'landing_funnel';

export interface ApiFunnelConsistencyMetadata {
  context: FunnelContext;
  email?: string;
  phone?: string;
  partnerSlug?: string;
  questionContent?: string;
  product: ApiProduct;
}

export interface FunnelConsistencyMetadata {
  context: FunnelContext;
  email?: string;
  phone?: string;
  partnerSlug?: string;
  questionContent?: string;
  product: Product;
}

export interface FunnelSharedLoginConsistencyMetadata {
  context: FunnelContext;
  partnerSlug?: string;
  email?: string;
  phone?: string;
  questionContent?: string;
  skuCode?: string;
  userType?: QuestionFunnelType;
}
