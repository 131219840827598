import { InformationCircleIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { capitalize } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocale } from 'hooks';

import { Button } from 'features/shared/buttons';
import { CrossIcon } from 'features/shared/icons';

import { formatDateToIntl } from 'utils/date';

import { Answer } from 'types/answer';
import { Lawyer } from 'types/lawyer';

import SanitizedContent from './SanitizedContent';

interface AnswerCardProps {
  onHandleProposal?(
    value: 'accepted' | 'refused',
    anwserId: number,
    formalNoticeId: string | null
  ): void;
  isHandleProposalLoading: boolean;
  answer: Answer;
  lawyer: Lawyer | null;
}

export default function AnswerCard({
  onHandleProposal,
  answer,
  isHandleProposalLoading,
  lawyer
}: AnswerCardProps) {
  const { t } = useTranslation('customer');
  const { locale } = useLocale();

  const onResponseProposal = useCallback(
    (value: 'accepted' | 'refused') => {
      if (onHandleProposal) {
        onHandleProposal(value, answer.id, answer.formalNotice?.id ?? null);
      }
    },
    [onHandleProposal, answer]
  );

  return (
    <Card>
      <CardContent>
        <Title gutterBottom variant="h3">
          {t('account.question.answer.title')}
        </Title>
        <div className="my-6 flex items-center">
          {lawyer !== null ? (
            <LawyerMediaInfo lawyer={lawyer} />
          ) : (
            <EkieTeamInfo />
          )}
        </div>
        {answer.formalNotice ? (
          <div className="mb-8">
            <div className="border-gray-500">
              <SanitizedContent content={answer.content} />
              {answer.formalNotice.status === 'pending' ? (
                <div className="flex flex-row items-center justify-start mt-6">
                  <Button
                    onClick={() => onResponseProposal('refused')}
                    isLoading={isHandleProposalLoading}
                    variant="custom"
                    size="large"
                    label={t('account.question.answer.refused')}
                    customClassNames="bg-transparent text-black border border-gray-700 hover:bg-black hover:text-white mr-4"
                  />
                  <Button
                    onClick={() => onResponseProposal('accepted')}
                    isLoading={isHandleProposalLoading}
                    variant="custom"
                    size="large"
                    label={t('account.question.answer.agree')}
                    customClassNames="bg-purple-900 hover:bg-purple-900 text-white font-medium"
                  />
                </div>
              ) : (
                <div className="bg-transparent w-fit text-black border border-gray-700 mt-5 px-4 text-base sm:py-3 flex items-center rounded font-medium py-2 opacity-60 cursor-not-allowed">
                  {answer.formalNotice.status === 'accepted' ? (
                    <CheckIcon className="h-4 w-4 stroke-black fill-black mr-2" />
                  ) : (
                    <CrossIcon className="h-4 w-4 stroke-black mr-2" />
                  )}
                  <p>
                    {answer.formalNotice.status === 'accepted'
                      ? t('account.question.answer.proposal.accepted')
                      : t('account.question.answer.proposal.refused')}
                  </p>
                </div>
              )}
            </div>
            <div className="mt-4 flex justify-start items-start">
              <InformationCircleIcon className="h-5 w-5 text-purple-900 mr-2" />
              <p className="font-sans text-sm text-gray-900">
                {answer.formalNotice.status === 'accepted'
                  ? t('account.question.answer.proposal.next', {
                      firstName: answer.lawyer?.firstName,
                      lastName: answer.lawyer?.lastName
                    })
                  : t('account.question.answer.proposal.disclaimer')}
              </p>
            </div>
          </div>
        ) : (
          <div>
            <SanitizedContent content={answer.content} />
            <div className="mt-2 text-right text-sm font-medium text-gray-700">
              {formatDateToIntl(
                answer.publishedDate || answer.createdDate,
                'long',
                locale
              )}
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export function LawyerMediaInfo({
  lawyer: { firstName, lastName, avatarUrl }
}: {
  lawyer: Lawyer;
}) {
  const { t } = useTranslation('customer');

  const capitalizedFirstName = capitalize(firstName);
  const capitalizedLastName = capitalize(lastName);

  return (
    <>
      <div className="mr-4 flex-shrink-0">
        {avatarUrl ? (
          <img className="h-16 w-16 rounded-full" src={avatarUrl} alt="" />
        ) : (
          <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
            <span className="text-lg font-medium leading-none text-white">
              {capitalizedFirstName[0]} {capitalizedLastName[0]}
            </span>
          </span>
        )}
      </div>
      <div>
        <Title variant="h3">
          {capitalizedFirstName} {capitalizedLastName}
        </Title>
      </div>
    </>
  );
}

export function EkieTeamInfo() {
  const { t } = useTranslation('customer');

  return (
    <>
      <div className="mr-4 flex-shrink-0">
        <span className="inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100">
          <svg
            className="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      </div>
      <div>
        <Title variant="h3">{t('account.question.answer.ekielegalteam')}</Title>
      </div>
    </>
  );
}
