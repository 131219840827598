export const ANALYTICS = {
  // Related to funnel for employees question content
  FUNNEL_BUSINESS_QUESTION_CONTENT: 'funnel_business_question_content',
  FUNNEL_BUSINESS_QUESTION_CONTACT: 'funnel_business_question_contact',
  FUNNEL_BUSINESS_QUESTION_SUCCESS: 'funnel_business_question_success',
  // Related to profile completion
  FINISH_PROFILE_COMPLETE: 'finish_profile_complete',
  FINISH_PROFILE_SKIP: 'finish_profile_skip',
  // Related to the question funnel for customers

  FUNNEL_CUSTOMER_QUESTION_CONTACT: 'funnel_customer_question_contact',

  FUNNEL_CUSTOMER_REGISTER_SUCCESS: 'funnel_customer_register_success',

  FUNNEL_CUSTOMER_QUESTION_SUBSCRIPTION_CONFIRM:
    'funnel_customer_question_subscription_confirm',
  FUNNEL_CUSTOMER_QUESTION_SUBSCRIPTION_SUCCESS:
    'funnel_customer_question_subscription_success',
  // Related to the subscription funnel for customer
  FUNNEL_CUSTOMER_SUBSCRIPTION_CONTACT: 'funnel_customer_subscription_contact',
  FUNNEL_CUSTOMER_SUBSCRIPTION_SUCCESS: 'funnel_customer_subscription_success',
  // Consultation success - used to filter on sources
  CONSULTATION_SUCCESS: 'consultation_success',
  // Question success - used to filter on sources
  QUESTION_SUCCESS: 'question_success',
  // Onboarding success
  ONBOARDING_SKIP: 'onboarding_skip',
  ONBOARDING_SUCCESS: 'onboarding_success',

  // Save Draft Answer
  ANSWER_SAVE_DRAFT: 'answer_save_draft',
  MICROPHONE_ASK_BUTTON_ACTIVATION: 'microphone_ask_button_activation',
  MICROPHONE_ASK_FORMAT_SUCCESS: 'microphone_ask_format_success',
  DOCUMENT_DOWNLOAD_BUTTON_CLICKED: 'document_download_button_clicked'
};

export const ANALYTICS_PAGE_VIEW = {
  // Related to funnel for employees activation
  APP_PAGE_VIEW: 'app_page_view'
};

// Sources of success for a consultation
export const INTENT_CONSULTATION_SOURCES = {
  QUESTIONS_QUESTION_CARD: 'questions_question_card',
  QUESTION_TIMELINE: 'question_timeline',
  QUESTION_BOTTOM: 'question_bottom',
  CONSULTATIONS_TOP: 'consultations_top',
  CONSULTATION_SCHEDULE: 'consultation_schedule',
  CONSULTATION_RESCHEDULE: 'consultation_reschedule'
};

// Sources of success for a question
export const INTENT_QUESTION_SOURCES = {
  QUESTIONS: 'questions',
  NEW_QUESTION: 'new_question',
  FUNNEL: 'funnel'
};
