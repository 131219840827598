import { privateAxios, publicAxios } from 'context/auth/auth.utils';

export async function requestPasswordReset(email: string): Promise<any> {
  await publicAxios.post('/auth/password/reset', {
    email
  });
}

export async function updatePassword(
  email: string,
  token: string,
  newPassword: string
) {
  await privateAxios.post('/auth/password/process', {
    email,
    token,
    password: newPassword
  });
}
