import { yupResolver } from '@hookform/resolvers/yup';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateEmailFromContent } from 'api/customer/customers';

import { LoginMagicFormInputs } from 'features/login/RequestMagicLink';
import { requestMagicLinkSchema } from 'features/login/validation';
import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';

export default function EmailForm({
  handleSuccess
}: {
  handleSuccess: () => void;
}) {
  const { mutate, isLoading } = useCreateEmailFromContent();

  const { t } = useTranslation('customer');

  const handleError = useErrorHandler();

  const { t: tForm } = useTranslation('form');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginMagicFormInputs>({
    resolver: yupResolver(requestMagicLinkSchema(tForm))
  });

  const onSubmit: SubmitHandler<LoginMagicFormInputs> = async (formData) => {
    const { email } = formData;
    mutate(
      { leadEmail: email },
      {
        onError: (error: any) => {
          handleError(error);
        },
        onSuccess: () => {
          handleSuccess();
        }
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col gap-4"
    >
      <TextInputGroup
        autofocus
        type="email"
        fullWidth
        placeholder={tForm('placeholder.email')}
        label={tForm('label.email')}
        name="email"
        register={register}
        error={errors.email}
      />
      <Button
        isLoading={isLoading}
        fullWidth
        submit
        size="medium"
        variant="primary"
        label={t('exclusiveContent.cta')}
      />
    </form>
  );
}
