import { GoogleRating, Section, Title } from 'components';
import { Fade } from 'components/transition';
import { INTENT_CONSULTATION_SOURCES } from 'constants/analytics';
import { NotificationList } from 'containers/notification';
import { filter } from 'lodash';
import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { useCreateAppointmentVoteMutation } from 'api/customer/mutations.index';

import { useCreateHref, useCustomer, useCustomerAccount } from 'hooks';

import ConsultationCard from 'features/customer/account/consultations/ConsultationCard';
import { ConsultationVoteModalUnsatisfied } from 'features/customer/account/consultations/vote';
import { PageLayout } from 'features/customer/account/content';
import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

import { Appointment, ConsultationStatus } from 'types/consultation';

export default function ConsultationsPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation('customer');
  const { isB2b } = useCustomer();

  const {
    mutate: createAppoitmentVoteOptimisticMutation,
    isLoading: isMutationLoading
  } = useCreateAppointmentVoteMutation();

  const [isUnsatisfiedModalOpen, setIsUnsatisfiedModalOpen] = useState(false);
  const [isSatisfiedModalOpen, setIsSatisfiedModalOpen] = useState(false);
  const [consultationVotePayload, setConsultationVotePayload] = useState<{
    appointmentId: Appointment['id'];
    note: number;
  }>({
    appointmentId: 0,
    note: 0
  });

  const { consultations, questions, customerUsageMeter } = useCustomerAccount();

  const handleSatisfiedVote = (
    appointmentId: Appointment['id'],
    note: number
  ) => {
    createAppoitmentVoteOptimisticMutation(
      {
        appointmentId,
        newAppointmentVote: {
          note
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['consultations']);
          setIsSatisfiedModalOpen(true);
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.vote.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        },
        onError: () => {
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('error.occurred')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        }
      }
    );
  };

  const handleUnsatisfiedVote = (
    appointmentId: Appointment['id'],
    note: number
  ) => {
    setConsultationVotePayload({
      appointmentId,
      note
    });
    setIsUnsatisfiedModalOpen(true);
  };

  const handleSubmitUnsatisfiedVote = (reason: string) => {
    const { appointmentId, note } = consultationVotePayload;
    createAppoitmentVoteOptimisticMutation(
      {
        appointmentId,
        newAppointmentVote: {
          note,
          reason
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['consultations']);
          setIsUnsatisfiedModalOpen(false);
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.vote.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        },
        onError: () => {
          setIsUnsatisfiedModalOpen(false);
          toast.custom(
            <Toast
              type="error"
              title={t('general.error')}
              message={t('error.occurred')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
        }
      }
    );
  };

  const handleConsultationVote = (
    appointmentId: Appointment['id'],
    note: number
  ) => {
    if (note >= 4) {
      return handleSatisfiedVote(appointmentId, note);
    }
    return handleUnsatisfiedVote(appointmentId, note);
  };

  const { href } = useCreateHref({
    path: `/account/consultations/choice`,
    params: {
      intentConsultationSource: INTENT_CONSULTATION_SOURCES.CONSULTATIONS_TOP
    }
  });

  const futureConsultations = sortBy(
    consultations.filter((c) =>
      [
        ConsultationStatus.PENDING,
        ConsultationStatus.VALIDATED,
        ConsultationStatus.QUALIFIED,
        ConsultationStatus.SCHEDULED,
        ConsultationStatus.SCHEDULED_APPOINTMENT,
        ConsultationStatus.HAS_INCIDENT,
        ConsultationStatus.TO_RESCHEDULE
      ].includes(c.status)
    ),
    'appointments[0].appointmentDate',
    'asc'
  ).reverse();

  const hasFutureConsultations = Boolean(futureConsultations.length);

  const pastConsultations = sortBy(
    consultations.filter((c) => c.status === ConsultationStatus.COMPLETED),
    'appointments[0].appointmentDate'
  ).reverse();

  const hasPastConsultations = Boolean(pastConsultations.length);

  const hasPendingVoteAmongConsultations = hasPastConsultations
    ? Boolean(filter(pastConsultations, { isConsultationVoted: false }))
    : false;

  const cancelledConsultations = consultations.filter(
    (c) => c.status === ConsultationStatus.CANCELLED
  );

  const hasCancelledConsultations = Boolean(cancelledConsultations.length);
  const questionsEligibleForConsultation = questions.questions
    .filter((q) => q.status === 30 || q.status === 40)
    .filter((q) => !q.hasLinkedConsultation);

  const hasQuestionsEligibleForConsultation =
    questionsEligibleForConsultation.length > 0;

  return (
    <PageLayout extra={<NotificationList />}>
      <Fade>
        <Section gutterBottom>
          <Title variant="h2" text={t('account.consultations.h2.future')} />
          {hasFutureConsultations && (
            <div className="flex flex-col gap-4">
              {futureConsultations.map((consultation) => (
                <ConsultationCard
                  key={consultation.id}
                  type="future"
                  consultation={consultation}
                />
              ))}
            </div>
          )}
          {hasQuestionsEligibleForConsultation && !hasFutureConsultations && (
            <>
              <Paragraph gutterBottom>
                {t('account.consultations.emptyQuestionAllowed')}
              </Paragraph>
              <Link className="w-full flex lg:w-fit" to={href}>
                <Button
                  variant="tertiary"
                  size="large"
                  label={t('account.consultations.cta')}
                  fullWidth
                />
              </Link>
            </>
          )}
          {!hasQuestionsEligibleForConsultation && !hasFutureConsultations && (
            <span>{t('account.consultations.emptyConsultations')}</span>
          )}
        </Section>
        {hasPastConsultations && (
          <Section>
            <Title variant="h2" text={t('account.consultations.h2.passed')} />
            <div className="flex flex-col gap-4">
              {pastConsultations.map((consultation) => (
                <ConsultationCard
                  key={consultation.id}
                  type="past"
                  consultation={consultation}
                  handleConsultationVote={handleConsultationVote}
                />
              ))}
            </div>
          </Section>
        )}
        {hasCancelledConsultations && (
          <Section>
            <Title
              variant="h2"
              text={t('account.consultations.h2.cancelled')}
            />
            <div className="flex flex-col gap-4">
              {cancelledConsultations.map((consultation) => (
                <ConsultationCard
                  key={consultation.id}
                  type="past"
                  consultation={consultation}
                />
              ))}
            </div>
          </Section>
        )}
        {hasPendingVoteAmongConsultations && (
          <>
            <LayoutModal
              isModalOpen={isSatisfiedModalOpen}
              handleModalClose={() => setIsSatisfiedModalOpen(false)}
            >
              <GoogleRating
                isB2b={isB2b}
                onClick={() => setIsSatisfiedModalOpen(false)}
              />
            </LayoutModal>
            <ConsultationVoteModalUnsatisfied
              isMutationLoading={isMutationLoading}
              isUnsatisfiedModalOpen={isUnsatisfiedModalOpen}
              handleSubmitUnsatisfiedVote={handleSubmitUnsatisfiedVote}
            />
          </>
        )}
      </Fade>
    </PageLayout>
  );
}
